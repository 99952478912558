<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('服务保障.参数名称')" prop="name">
                <a-input v-model="queryParam.name" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('商品参数.参数名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()"  class="plus">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"class="edit">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['goods:guarantee:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['goods:guarantee:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="status" slot-scope="text, record">
            <a-switch @change="(checked) => onStatusChange(checked,record)" :checked-children="$t('通用.文本.启用')"
                      :un-checked-children="$t('通用.文本.禁用')" :checked="record.status===1"/>
        </span>
        <span slot="operation" slot-scope="text, record">
     
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['goods:guarantee:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['goods:guarantee:remove']" />
         <a @click="handleDelete(record)" v-hasPermi="['goods:guarantee:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGuarantee,listGuarantee, delGuarantee,statusLabel } from '@/api/goods/guarantee'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Guarantee',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: this.$t('服务保障.主键id'),
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('服务保障.参数名称'),
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('服务保障.状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('服务保障.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('服务保障.备注'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    onStatusChange (checked, record) {
      let statusValue = checked ? 1 : 0;
      let data = {id:record.id, status: statusValue}
      statusLabel(data).then(response => {
        if (response.success) {
          this.$message.success(
            this.$t('通用.文本.修改成功'),
            3
          );
          this.$emit('ok')
          record.status=statusValue
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /** 查询服务保障列表 */
    getList () {
      this.loading = true
     pageGuarantee(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGuarantee(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('goods/guarantee/export',
            ...that.queryParam
          , `服务保障_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style>
.plus {
  color: #fff;
  background-color: #00D31D;
}
.edit {
  color: #fff;
  background-color: #FF9B7B;
}
.delete {
  color: #fff;
  background-color: #FF0000;
}
.copy {
  color: #fff;
  background-color: #FFB936;
}
.check {
  color: #fff;
  background-color: #1EBCFF;
}
.stop {
  color: #fff;
  background-color: #FF95A2;
}
.excel {
  color: #fff;
  background-color: #FA541B;
}
.excel1 {
  color: #fff;
  background-color: #FA541B;
}
</style>